var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "id": "tour-card",
      "title": "Tour"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeTour) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.tourStart
    }
  }, [_vm._v(" Start Tour ")]), _c('app-tour', {
    attrs: {
      "steps": _vm.steps
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }